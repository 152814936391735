import React from 'react'
import { graphql } from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import Collaboration from '../components/collaboration'
import SEO from '../components/seo'
import ContentContainer from '../containers/content'

export const query = graphql`
  query CollaborationTemplateQuery($id: String!) {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      openGraph {
        image {
          ...SanityImage
        }
      }
    }
    collaboration: sanityCollaboration(id: { eq: $id }) {
      id
      title
      artist
      venue
      location
      startDate
      endDate
      previewImageSize {
        widthDesktop
      }
      tags {
        _id
        title
      }
      mainImage {
        ...SanityImage
        asset {
          fluid(maxWidth: 2400) {
            ...GatsbySanityImageFluid
          }
        }
        title
        credits
        alt
      }
      pressLinks {
        title
        externalLink
        download {
          asset {
            url
          }
        }
      }
      artistListTitle
      artistList {
        title
        slug {
          current
        }
      }
      showArtistList
      relatedCollaborationsTitle
      relatedCollaborations {
        title
        artist
        location
        startDate
        endDate
        slug {
          current
        }
      }
      slug {
        current
      }
      metaDescription
      openGraph {
        image {
          ...SanityImage
        }
      }
      _rawFormattedTitle
      _rawPressLinks(resolveReferences: { maxDepth: 5 })
      _rawBioLinks(resolveReferences: { maxDepth: 5 })
      _rawGallerySlides(resolveReferences: { maxDepth: 5 })
      _rawBody(resolveReferences: { maxDepth: 5 })
      _rawArtistGroup(resolveReferences: { maxDepth: 5 })
    }
  }
`

const CollaborationTemplate = props => {
  const { data, errors } = props
  const site = (data || {}).site
  const siteOgImage = site.openGraph.image
  const collaboration = data && data.collaboration
  const collaborationOgImage = collaboration.openGraph && collaboration.openGraph.image
  const SEOtitle = `${collaboration.title}, ${collaboration.artist}`

  return (
    <>
      <ContentContainer section="collaborations" singleView>
        {errors && <SEO title="GraphQL Error" />}
        {collaboration && (
          <SEO
            title={SEOtitle || site.title}
            description={collaboration.metaDescription || site.description}
            keywords={site.keywords}
            image={collaborationOgImage || siteOgImage}
          />
        )}

        {errors && (
          <Container>
            <GraphQLErrorList errors={errors} />
          </Container>
        )}

        {collaboration && <Collaboration {...collaboration} />}
      </ContentContainer>
    </>
  )
}

export default CollaborationTemplate
